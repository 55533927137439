// ------------>TEST SERVER<---------------
// const attachmenturl = "http://104.236.58.99";

//-------------->Final PROD SERVER<----------------
// const attachmenturl = "http://159.65.130.62";
//const attachmenturl = "https://api.abc.clientelecrm.com";
const attachmenturl = "https://api.seca.clientelecrm.com/";

//-------------->AWS PROD SERVER<----------------
// const attachmenturl = "http://54.206.51.82";
// const attachmenturl = "https://api.intstudentworld.com";

export default attachmenturl;
